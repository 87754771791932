import type { Schema } from '@biteinc/common';

export const createUsersSchema: Schema.Model = {
  displayName: 'users',
  type: 'object',
  fields: {
    usernames: {
      type: 'longString',
      displayName: 'Emails',
      required: true,
      tooltip: {
        text: 'Create multiple users by entering email addresses on separate lines or separated by commas.',
      },
    },
    locationGroupId: {
      type: 'mongoId',
      displayName: 'Location Access',
      required: true,
      ui: 'dropdown',
    },
    userRoleId: {
      type: 'mongoId',
      displayName: 'Role',
      required: true,
      ui: 'dropdown',
    },
  },
};
