import { createUsersSchema } from './schemas/create_users_schema';

app.NewUsersDetailsView = app.BaseDetailsView.extend({
  initialize(options) {
    options.dismissOnSave = true;

    app.BaseDetailsView.prototype.initialize.apply(this, arguments);

    if (app.userRoleList && !app.userRoleList.hasBeenFetched()) {
      this.listenToOnce(app.userRoleList, 'reset', this.render);
      app.userRoleList.fetch({ reset: true });
    }
  },

  getSchema() {
    const schema = app.JsonHelper.deepClone(createUsersSchema);
    if (!app.org || app.location) {
      delete schema.fields.locationGroupId;
    }
    return schema;
  },

  _save(data, opts, $saveButton) {
    const self = this;
    const reqId = $saveButton.initLoadingButton(
      $saveButton.html(),
      this._saveButtonTitles()[1],
      this._saveButtonTitles()[2],
    );

    opts.error = () => {
      $saveButton.loadingDidFinishWithError(reqId);
    };
    opts.success = (_model, response) => {
      $saveButton.loadingDidFinishSuccessfully(reqId);

      response.data.users.forEach((user) => {
        self.collection.add(new app.User(user));
      });

      setTimeout(() => {
        this.isDestroyed = true;
        this.trigger(app.ModalView.Events.ModalViewShouldHide, this);
      }, 1500);
    };

    this.model.save(data, opts);
  },
});
